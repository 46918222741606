import { actionTypes } from './actionTypes/loginActionTypes';
import request from '../../src/config/axios';
import { LoginServices } from '../services/loginServices';
import { noty } from '../services/notyService';
/**
 * Action creators. Used to dispatch actions with Redux.
 * Actions can be simple [assignmentActions.closeAssignment()] or
 * complex to handle AJAX requests [assignmentActions.getAssignment()].
 * For actions that include AJAX requests, we dispatch two actions:
 *  -request (in case we need to update store on request initiation)
 *  -success OR failure (to update store with relevant response data)
 */
export const loginActions = {
  IsUserLoggedIn,
  loginLoader,
  fetchUserConfig,
  uplaodedFileScanLoader,
  getOTPResetPassword,
  getOTPResetPasswordLoader,
  clearPWDData,
  ProfilePicLoader,
  ProfilePic
};

async function fetchUserConfig(pyuseridentifier) {
  return new Promise((resolve, reject) => {
    // if (!window.localStorage.getItem('modules')) {
    if (true) {
      request({
        method: 'get',
        url: window.BASEURL + '/UserConfig',
        pyuseridentifier: pyuseridentifier || '',
      })
        .then((resp) => {
          var setItemPromises = [];

          for (let key in resp.accessDetails) {
            setItemPromises.push(
              new Promise((res, rej) => {
                window.localStorage.setItem(key, resp.accessDetails[key]);
                res();
              })
            );
          }

          //set the value to session storage, so that on refresh the value is being fetch from session

          setItemPromises.push(
            new Promise((res, rej) => {
              window.localStorage.setItem('isLoggedIn', true);
              res();
            })
          );

          Promise.all(setItemPromises).then(() => {
            resolve(resp);
          });
        })
        .catch(reject);
    }
  });
}

function IsUserLoggedIn(loggedInFlag, Username) {
  return (dispatch) => {
    if (loggedInFlag) {
      fetchUserConfig(Username)
        .then(() => {
          dispatch(success(loggedInFlag));
        })
        .catch(() => {
          dispatch(success(loggedInFlag));
        });
    } else {
      dispatch(success(loggedInFlag));
    }
  };

  function success(data) {
    return { type: actionTypes.IsLoggedIn, loggedInFlag };
  }
}

function loginLoader({ authType }) {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: 'loginLoader', authType };
  }
}

function uplaodedFileScanLoader(isLoading) {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: 'uplaodedFileScanLoader', isLoading };
  }
}

function getOTPResetPassword(params) {
  return (dispatch) => {
    LoginServices.ResetPaswordPost(params).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function success(data) {
    if (data.pyNote) {
      noty(data?.status?.toLowerCase() === 'success' ? 'success' : 'error', data.pyNote);
    }
    return { type: actionTypes.RESET_PASSWORD_GET_OTP_SUCCESS, data };
  }
  function failure(error) {
    return { type: actionTypes.RESET_PASSWORD_GET_OTP_FAILURE, error };
  }
}

function getOTPResetPasswordLoader() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: actionTypes.RESET_PASSWORD_LOADER };
  }
}

function clearPWDData() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: actionTypes.CLEAR_PWD_DATA };
  }
}

async function getProfilePic() {
  const accesstoken = localStorage.getItem('accessToken');

  // Example: Fetching image data from an API
  const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', {
    headers: {
      Authorization: `Bearer ${accesstoken}`,
    },
  });
  const data = await response.blob();
  return URL.createObjectURL(data);
}

function ProfilePic(params) {
  return (dispatch) => {
    getProfilePic().then(
      (data) => {
        dispatch(success(data));
      },
      (err) => {
        dispatch(failure(err));
      }
    );
  };

  function success(data) {
    
    return { type: 'PROFILE_PIC_SUCCESS', data };
  }
  function failure(error) {
    return { type: 'PROFILE_PIC_FAILURE', error };
  }
}

function ProfilePicLoader() {
  return (dispatch) => {
    dispatch(success());
  };

  function success() {
    return { type: 'PROFILEPIC_LOADER' };
  }
}