import { makeStyles } from '@mui/styles';

const globalUseStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  marginTopZero:{
    marginTop:'0px !important'
  },
  error: {
    color: `${theme.palette.error.main} !important`,
  },
  infoBg: {
    background: '#f3fbff',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'center',
  },
  flexRowEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexRowStart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  flexRowJustifySpaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexRowJustifySpaceAround: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  flexRowAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexJustifyCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  approvedBorder: {
    borderLeft: `4px solid ${theme.statuscolors.approved}`,
  },
  pendingBorder: {
    borderLeft: `4px solid ${theme.statuscolors.pending}`,
  },
  rejectedBorder: {
    borderLeft: `4px solid ${theme.statuscolors.rejected}`,
  },
  withdrawnBorder: {
    borderLeft: `4px solid ${theme.statuscolors.withdrawn}`,
  },
  updateBorder: {
    borderLeft: `4px solid ${theme.statuscolors.update}`,
  },
  newBorder: {
    borderLeft: `4px solid ${theme.statuscolors.New}`,
  },
  approved: {
    background: theme.statuscolors.approved,
  },
  pending: {
    background: theme.statuscolors.pending,
  },
  rejected: {
    background: theme.statuscolors.rejected,
  },
  withdrawn: {
    background: theme.statuscolors.withdrawn,
  },
  update: {
    background: theme.statuscolors.update,
  },
  New: {
    background: theme.statuscolors.New,
  },
  statusColorDot: {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    display: 'inline-block',
  },
  textOverFlow: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    textOverflow: 'ellipsis',
  },
  displayNone: {
    display: 'none !important',
  },
  displayBlock: {
    display: 'block',
  },
  displayInlineblock: {
    display: 'inline-block',
  },
  visibilityHidden: {
    visibility: 'hidden',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  textAlignCenter: {
    textAlign: 'center !important',
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  statusStyling: {
    boxShadow: theme.palette.customBoxShadows.boxShadow,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  wordBreakAll: {
    wordBreak: 'break-all',
  },
  wordBreak_BreakWord: {
    wordBreak: 'break-word',
  },
  lightColor: {
    color: theme.palette.customColors.bismark,
    wordBreak: 'break-word',
  },
  styledText: {
    fontSize: '12px',
    color: theme.palette.customColors.bismark,
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  downloadFile: {
    fontSize: '12px',
    display: 'flex',
    wordBreak: 'break-all',
  },
  downloadIcon: {
    fontSize: '1.2em',
  },
  outlineNone: {
    '&:focus': {
      outline: 'none !important',
    },
  },
  positionRelative: {
    position: 'relative !important',
  },
  mainIconColor: {
    color: theme.palette.customColors.bismark,
  },
  profilePic: {
    color: `${theme.palette.background.paper} !important`,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.grey[200]}`,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    fontSize: '20px',
  },
  smallProfilePic: {
    color: theme.palette.background.paper,
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: theme.spacing(5),
    height: theme.spacing(5),
    fontSize: '18px',
    border: `3px solid ${theme.palette.grey[200]}`,
  },
  attributes: {
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[400]} !important`,
  },
  copyText: {
    cursor: 'pointer',
    '&&:hover': {
      color: '#007ca2',
      fontWeight: 600,
    },
  },
  dataGridColumWrap: {
    whiteSpace: 'break-spaces',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default globalUseStyles;
