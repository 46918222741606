import { useState, useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { defaultTheme, light, dark } from './defaultTheme';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const useDarkMode = () => {
  const [theme, setTheme] = useState(window.localStorage.getItem('theme'));
  const mainTheme = useTheme();
  const mobileMatchMediaQuery = useMediaQuery(mainTheme.breakpoints.down('sm'));

  const color = theme === 'dark' ? dark : light;
  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('light');
      // window.localStorage.setItem('theme', 'dark');
    } else {
      setTheme('dark');
      // window.localStorage.setItem('theme', 'light');
    }
  };

  const updatedTheme = {
    ...defaultTheme,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            backgroundColor:
              theme === 'light' ? (mobileMatchMediaQuery ? '#3f51b5' : '#fafafa') : '#303030',
          },
        },
      },
    },
    palette: {
      ...defaultTheme.palette,
      mode: theme === 'light' ? 'light' : 'dark',
      primary: {
        main: theme === 'dark' ? '#eeeeee' : '#3f51b5',
      },
      secondary: {
        main: theme === 'dark' ? '#f48fb1' : '#f50057',
      },
      error: {
        main: theme === 'dark' ? '#ff8db3' : '#f44336',
      },
    },
  };

  const themeConfig = createTheme({ ...updatedTheme });

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme');

    if (localTheme) {
      setTheme(localTheme);
    } else {
      // window.localStorage.setItem('theme', 'light');
    }
  }, []);

  return [theme, toggleTheme, themeConfig, color];
};
